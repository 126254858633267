<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <v-row>
      <v-col cols="4" v-for="item in pengumumans" :key="item.pengumuman_id">
        <v-card
          v-if="item.pengumuman_jenis === 'penting'"
          class="mb-5 white--text"
          min-height="300"
          color="error"
        >
          <v-card-title class="caption">
            <div>
              <v-icon small left color="white">mdi-clock-outline</v-icon>
              <span class="white--text">
                {{ tglFormat(item.pengumuman_created_at) }}
              </span>
            </div>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

          <div class="px-3">
            <h2>{{ item.pengumuman_judul }}</h2>
          </div>

          <v-card-text class="white--text">
            <span v-html="item.pengumuman_isi"></span>
          </v-card-text>
        </v-card>

        <v-card v-else class="mb-5" min-height="300">
          <v-card-title class="caption">
            <div>
              <v-icon small left>mdi-clock-outline</v-icon>
              <span class="text--disabled">
                {{ tglFormat(item.pengumuman_created_at) }}
              </span>
            </div>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider class="mx-3 mt-n3 mb-3"></v-divider>

          <div class="px-3">
            <h2>{{ item.pengumuman_judul }}</h2>
          </div>

          <v-card-text>
            <span v-html="item.pengumuman_isi"></span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-pagination v-model="page" :length="totalRows"></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    page() {
      this.getData();
      window.scrollTo(0, 0);
    },
  },

  data: () => ({
    session: "",

    page: 0,
    totalRows: 0,

    pengumumans: [],

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Informasi",
        disabled: true,
        href: "",
      },
      {
        text: "Pengumuman",
        disabled: false,
        href: "/informasi/pengumuman",
      },
    ],
  }),

  async mounted() {
    this.getData();
  },

  methods: {
    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    getData() {
      this.http
        .get(
          process.env.VUE_APP_API_BASE + "pengumuman/public?page=" + this.page,
          {
            headers: {
              Authorization: "Bearer " + this.session.token,
            },
          }
        )
        .then((res) => {
          this.pengumumans = res.data.data;
          this.page = res.data.page_number;
          this.totalRows = res.data.page_count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
